/* 
//////////////////////////////////////////////////////
*/


html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;  
  
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;  
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translate(-50%);
}

.bottom-padding {
  padding: 0 0 10px 0;
}
/* 
//////////////////////////////////////////////////////
*/

.bodyDefault {
  background: #14CCB6;
  background: linear-gradient(90deg,#14CCB6 0%, #0DC1BA 90%,#05B6BE 100%); 
  color: #0b4d4a;
}

.bodyDefault a {
  color: #0b4d4a;
}

.bodyDefault .logo {
  filter: saturate(1000%);  
}

.bodyDefault .iconRefresh {
  color: #000000;
  opacity: 0.4;
}

.bodyDefault .iconNotifications {
  color: #000000;
  opacity: 0.4;
}

.bodyDefault .checkbox {
  background-color: #087773;
}

.bodyDefault .button {
  background-color: #087773;
}

.bodyDefault .select {
  background-color: #087773;
}

.bodyDefault #clockdiv > div  {
  background-color: #0ca19c;
}

.bodyDefault #clockdiv div > span {
  background-color: #087773;
}

/* 
//////////////////////////////////////////////////////
*/

.bodyWarning {
  background-color: #FFC500;
  background: linear-gradient(210deg,#FFD401 0%, #FFC500 50%,#FFB900 100%); 
  color: #664b00;
}

.bodyWarning a {
  color: #664b00;
}

.bodyWarning .logo {
  filter: saturate(500%);
}

.bodyWarning .iconRefresh {
  color: #000000;
  opacity: 0.5;
}

.bodyWarning .iconNotifications {
  color: #000000;
  opacity: 0.5;
}

.bodyWarning .checkbox {
  background-color: #885d00;
}

.bodyWarning .button {
  background-color: #885d00;
}

.bodyWarning .select {
  background-color: #885d00;
}

.bodyWarning #clockdiv > div  {
  background-color: #885d00;
}

.bodyWarning #clockdiv div > span {
  background-color: #faa200;  
}

/* 
//////////////////////////////////////////////////////
*/

.bodyComplete {
  background-color: #FFFFFF;
  background: linear-gradient(30deg,#CCCCCC 0%, #EEEEEE 50%,#FFFFFF 100%); 
  color: #333333;
}

.bodyComplete a {
  color: #333333;
}

.bodyComplete .logo {
  /* filter: hue-rotate(180deg) saturate(10000%) grayscale(100%); */
  filter: hue-rotate(180deg) saturate(20000%) grayscale(100%) contrast(40%);
}

.bodyComplete .iconRefresh {
  color: #000000;
  opacity: 0.7;
}

.bodyComplete .iconNotifications {
  color: #000000;
  opacity: 0.7;
}

.bodyComplete .checkbox {
  background-color: #494949;
}

.bodyComplete .button {
  background-color: #494949;
}

.bodyComplete .select {
  background-color: #494949;
}

.bodyComplete #clockdiv > div  {
  background-color: #999999;
}

.bodyComplete #clockdiv div > span {
  background-color: #494949;
}

/* 
//////////////////////////////////////////////////////
*/

