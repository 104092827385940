
.header {
  width: 280px;
  margin: 0 auto;
}

.logo {
  width: 60px;
  height: 60px;  
  margin: 0 10px 0 0;
  float: left;
  position: relative;
  bottom: 5px;
}

.headerText {
  text-align: left;
}

.meetingTime {
  margin: 15px 0px 10px 0px;
}

.iconRefresh {
  position: relative;
  bottom: -9px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

.iconNotifications {
  position: relative;
  bottom: -9px;
  margin: 0 0 0 5px;
  cursor: pointer;
}

#meetingDurationLabel {
  margin: 0 0px 0 10px;
}
#meetingSpeedyLabel {
  margin: 0 5px 0 10px;
}

#meetingSpeedy{
  vertical-align: middle -webkit-baseline-middle;
  position: relative;
  bottom: -4px;
}

#meetingWarningLabel {
  margin: 0 0 0 10px;  
}

.label {
  font-weight: 400;
  font-size: 15px;
  margin: 40px 0px 20px;
}

.checkbox {
  border-radius: 3px;
  border: 0px;
  color: white;
  margin: 2px;
  padding: 5px 10px 5px 10px;
  text-align: center;
  width: 20px;
  height: 20px;
}

.button {
  border-radius: 5px;
  border: 0px;
  color: white;
  margin: 2px;
  padding: 5px 10px 5px 10px;
  text-align: center;
  font-size: 15px;
}

.select {
  border-radius: 5px;
  border: 0px;
  color: white;
  margin: 2px;
  padding: 5px 2px 5px 2px;

  text-align: center;
  font-size: 15px;
}

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
}

#clockdiv > div {
  padding: 10px;
  margin: 10px 5px 10px 5px;
  border-radius: 5px;
  display: inline-block;
}

#clockdiv div > span {
  padding: 15px;
  border-radius: 5px;
  width: 40px;
  display: inline-block;
}

.clocktext {
  padding-top: 5px;
  font-size: 16px;
  font-weight: 500;
}
